import { HAlign, VAlign } from '../../enums/align';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export type RowProps = {
  children: ReactNode;
  as?: string;
  limit?: boolean;
  vAlign?: VAlign;
  hAlign?: HAlign;
  gutter?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

function GridRow(props: RowProps): JSX.Element {
  const {
    as: Tag = 'div',
    children,
    vAlign,
    hAlign,
    gutter = true,
    className,
    limit = false,
    style,
  } = props;
  const rowClassName = classNames(
    'row',
    {
      [`limited`]: limit,
      [`vAlign-${vAlign}`]: !!vAlign,
      [`hAlign-${hAlign}`]: !!hAlign,
      [`col-gapless`]: !gutter,
    },
    className
  );
  return React.createElement(
    Tag,
    {
      className: rowClassName,
      style,
    },
    children
  );
}

export default GridRow;
