import { ThemeContext } from '../../enums/theme'
import { getIconByName } from '../../icons'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Icon from '../Icon/Icon'
import Permalink from '../Permalink/Permalink'
import styles from './AnnouncementBar.module.scss'
import FreeText from '../FreeText/FreeText'
import { Link } from 'gatsby'

export type AnnouncementBarProps = {
  title: string
  to: string
  target?: '_blank' | '_self'
  className?: string
}

export default function AnnouncementBar(
  props: AnnouncementBarProps
): JSX.Element {
  const { title, to, className, target = '_self' } = props
  const { theme } = useContext(ThemeContext)
  return (
    <div
      className={classNames(
        styles.container,
        styles[`theme__${theme}`],
        className
      )}
    >
      <Link to={to} target={target}>
        <FreeText type="sm" as="span" color="white">
          <span>{title}</span>

          <Icon
            icon={getIconByName('arrow-right')}
            className={styles.arrow}
            size={18}
          />
        </FreeText>
      </Link>
    </div>
  )
}
