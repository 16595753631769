import { FormInputEventProps } from '../../types/events'
import { FormElementSize, FormElementStateProps } from '../../types/forms'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Icon from '../Icon/Icon'
import Loading from '../Loading/Loading'
import styles from './Button.module.scss'

export type ButtonProps = {
  id?: string
  children?: ReactNode
  type?: 'submit' | 'button'
  className?: string
  size?: FormElementSize
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  block?: boolean
  theme?: 'primary' | 'light' | 'tetriary' | 'blackTransparent'
} & FormElementStateProps &
  FormInputEventProps<HTMLButtonElement>

function Button(props: ButtonProps): JSX.Element {
  const {
    id,
    children,
    icon,
    className,
    type = 'button',
    size = 'normal',
    theme = 'primary',
    loading,
    disabled,
    block,
    onClick,
  } = props
  return (
    <button
      type={type}
      id={id}
      disabled={loading || disabled}
      onClick={onClick}
      className={classNames(
        styles.button,
        styles[`theme__${theme}`],
        styles[`size__${size}`],
        { [styles.iconOnly]: icon && !children, [styles.block]: block },
        className
      )}
    >
      {loading && <Loading className={styles.loading} />}
      {children && <span className={styles.label}>{children}</span>}
      {icon && !loading && <Icon icon={icon} className={styles.icon} />}
    </button>
  )
}

export default Button
