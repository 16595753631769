import { getIconByName } from '../../icons';
import React from "react"
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import styles from './Loading.module.scss';

export type LoadingProps = {
  size?: number;
  className?: string;
};

function Loading({ size = 16, className }: LoadingProps): JSX.Element {
  return (
    <div className={classNames(styles.loading, className)}>
      <Icon icon={getIconByName('spinner')} size={size} />
    </div>
  );
}

export default Loading;
