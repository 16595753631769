import React from 'react'
import { NavigationLink } from '../../types/link'
import { Link } from 'gatsby'

export type PermalinkProps = {
  className?: string
  activeClassName?: string
  onClick?: (e: React.MouseEvent) => void
  itemProp?: string
  isExternal?: boolean
  isBlank?: boolean
} & NavigationLink & React.HTMLAttributes<HTMLElement>

const Permalink = (props: PermalinkProps): JSX.Element => {
  const { isExternal = false, children, isBlank, onClick, title, ...routeProps } = props
  const target = isBlank ? '_blank' : undefined
  if (!isExternal) {
    return (
      <Link onClick={onClick} {...routeProps} title={title}>
        {children}
      </Link>
    )
  } else {
    return (
      <a href={routeProps.to} className={routeProps.className} target={target} title={title}>
        {children}
      </a>
    )
  }
}

export default Permalink
