import { useState, useEffect } from 'react'

export const MEDIA_QUERY_DESKTOP = '(min-width: 1024px)'
export const MEDIA_QUERY_TABLET = '(min-width: 768px and max-width: 1023px)'
export const MEDIA_QUERY_TABLET_BELOW = '(max-width: 1023px)'
export const MEDIA_QUERY_MOBILE = '(max-width: 767px)'

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(false)

  useEffect(() => {
      const media = window.matchMedia(query)
      if (media.matches !== matches) {
        setMatches(media.matches)
      }
      const listener = () => {
        setMatches(media.matches)
      }
      try {
        media.addEventListener('change', listener)
        return () => media.removeEventListener('change', listener)
      }
      catch {
        media.addListener(listener)
        return () => media.addListener(listener)
      }
  }, [matches, query])

  return matches
}
