import { AttachmentDataLike, GatsbyResolvedAsset } from './types/image'
import { getImage, ImageDataLike } from 'gatsby-plugin-image'
import { includes, prop, toLower } from 'rambdax'

const EXT_IMG = ['.png', '.jpg', '.jpeg', '.webp', '.gif']
const EXT_ATTACHMENT = ['.pdf', '.docx', '.svg', '.mp4', '.mpeg']

export function resolveAsImage(asset: GatsbyResolvedAsset) {
  if (includes(asset.ext, EXT_IMG)) {
    return getImage(asset.resolved as ImageDataLike)
  }
  return undefined
}

export function resolveAsAttachment(asset: GatsbyResolvedAsset) {
  if (includes(toLower(asset.ext), EXT_ATTACHMENT)) {
    return prop('publicURL', asset.resolved as AttachmentDataLike)
  }
  return undefined
}
