import classNames from 'classnames'
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import styles from './FreeText.module.scss'

export type FreeTextProps = {
  children: ReactNode
  as?: keyof HTMLElementTagNameMap
  type?: 'lg' | 'md' | 'm' | 'sm'
  color?: 'primary' | 'secondary' | 'tetriary' | 'white'
  className?: string
  innerHTML?: ReactNode
  style?: React.CSSProperties
}
export default function FreeText(props: FreeTextProps) {
  const {
    as = 'div',
    color = 'primary',
    className,
    children,
    type = 'md',
    innerHTML,
    style,
  } = props
  let shouldUseChildren = children
  const elmProps: Partial<HTMLAttributes<'div'>> = {
    className: classNames(
      [styles[`type__${type}`]],
      [styles[`color__${color}`]],
      styles.text,
      className
    ),
    style,
  }

  if (innerHTML) {
    shouldUseChildren = null
    elmProps.dangerouslySetInnerHTML = {
      __html: children,
    }
  }
  return React.createElement(as, elmProps, shouldUseChildren)
}
