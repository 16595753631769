import classNames from 'classnames';
import React, { ReactNode } from 'react';

export type ColumnProps = {
  children: ReactNode;
  as?: string;
  size?: number;
  sm?: number;
  md?: number;
  lg?: number;
  auto?: boolean;
  gutter?: boolean;
  className?: string;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLElement>;

function GridColumn(props: ColumnProps): JSX.Element {
  const {
    as: Tag = 'div',
    children,
    size,
    sm,
    md,
    lg,
    auto = false,
    className,
    style,
    ...ariaProps
  } = props;
  const columnClassName = classNames(
    'column',
    {
      [`col-auto`]: auto,
      [`col-${size}`]: !!size,
      [`col-xs-${size}`]: !!size,
      [`col-sm-${md}`]: !!md,
      [`col-sm-${sm}`]: !!sm,
      [`col-lg-${lg}`]: !!lg,
    },
    className
  );
  return React.createElement(
    Tag,
    {
      ...ariaProps,
      className: columnClassName,
      style,
    },
    children
  );
}

export default GridColumn;
