import { ReactComponent as SpinnerIcon } from './svg/spinner.svg'
import { ReactComponent as SearchIcon } from './svg/search.svg'
import { ReactComponent as ArrowRight } from './svg/arrow-right.svg'
import { ReactComponent as ChevronLeft } from './svg/chevron-left.svg'
import { ReactComponent as ChevronRight } from './svg/chevron-right.svg'
import { ReactComponent as ArrowUp } from './svg/arrow-up.svg'
import { ReactComponent as ArrowDown } from './svg/arrow-down.svg'
import { ReactComponent as ArrowForward } from './svg/arrow-forward.svg'
import { ReactComponent as ArrowBack } from './svg/arrow-back.svg'
import { ReactComponent as BurgerIcon } from './svg/burger.svg'
import { ReactComponent as TimesIcon } from './svg/times.svg'
import { ReactComponent as CheckCircleIcon } from './svg/check-circle.svg'

import { ReactComponent as FacebookIcon } from './svg/facebook.svg'
import { ReactComponent as InstagramIcon } from './svg/instagram.svg'
import { ReactComponent as LinkedinIcon } from './svg/linkedin.svg'
import { ReactComponent as TwitterIcon } from './svg/twitter.svg'
import { ReactComponent as WhatsappIcon } from './svg/whatsapp.svg'
import { ReactComponent as TelegramIcon } from './svg/telegram.svg'
import { ReactComponent as LinkIcon } from './svg/link.svg'

import { ReactComponent as EarthIcon } from './svg/earth.svg'
import { ReactComponent as PhoneIcon } from './svg/phone.svg'
import { ReactComponent as EmailIcon } from './svg/email.svg'
import { ReactComponent as DownloadIcon } from './svg/download.svg'
import { ReactComponent as TickIcon } from './svg/tick.svg'
import { ReactComponent as InfoIcon } from './svg/info.svg'
import { ReactComponent as ActiveTickIcon } from './svg/active-tick.svg'
import { ReactComponent as CalendarIcon } from './svg/calendar.svg'
import { ReactComponent as ArrowNorthEastIcon } from './svg/arrow-north-east.svg'
import { ReactComponent as ArrowPlayIcon } from './svg/arrow-play.svg'
import { ReactComponent as ArrowCircleDownIcon } from './svg/arrow-circle-down.svg'
import { ReactComponent as SuccessIcon } from './svg/success.svg'
import { ReactComponent as EventIcon } from './svg/event.svg'
import { ReactComponent as LocationIcon } from './svg/location.svg'
import { ReactComponent as ScheduleIcon } from './svg/schedule.svg'
import { ReactComponent as CloseIcon } from './svg/close.svg'
import { ReactComponent as HeartIcon } from './svg/heart.svg'
import { ReactComponent as SuccessBlackIcon } from './svg/success-black.svg'
import { ReactComponent as AlgbraLogo } from './svg/algbra-logo.svg'
import { ReactComponent as ArrowOutward } from './svg/arrow-outward.svg'

export type IconNameType =
  | 'spinner'
  | 'search'
  | 'arrow-right'
  | 'chevron-left'
  | 'chevron-right'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-forward'
  | 'arrow-back'
  | 'burger'
  | 'times'
  | 'check-circle'
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'twitter'
  | 'whatsapp'
  | 'telegram'
  | 'phone'
  | 'email'
  | 'link'
  | 'download'
  | 'earth'
  | 'tick'
  | 'info'
  | 'active-tick'
  | 'calendar'
  | 'arrow-north-east'
  | 'arrow-play'
  | 'arrow-circle-down'
  | 'success'
  | 'event'
  | 'location'
  | 'schedule'
  | 'close'
  | 'heart'
  | 'success-black'
  | 'algbra-logo'
  | 'arrow-outward'

export function getIconByName(
  name: IconNameType
): React.FC<React.SVGProps<SVGSVGElement>> {
  switch (name) {
    case 'spinner':
      return SpinnerIcon
    case 'search':
      return SearchIcon
    case 'arrow-right':
      return ArrowRight
    case 'chevron-left':
      return ChevronLeft
    case 'chevron-right':
      return ChevronRight
    case 'arrow-up':
      return ArrowUp
    case 'arrow-down':
      return ArrowDown
    case 'burger':
      return BurgerIcon
    case 'times':
      return TimesIcon
    case 'check-circle':
      return CheckCircleIcon
    case 'facebook':
      return FacebookIcon
    case 'instagram':
      return InstagramIcon
    case 'twitter':
      return TwitterIcon
    case 'whatsapp':
      return WhatsappIcon
    case 'telegram':
      return TelegramIcon
    case 'link':
      return LinkIcon
    case 'linkedin':
      return LinkedinIcon
    case 'phone':
      return PhoneIcon
    case 'email':
      return EmailIcon
    case 'download':
      return DownloadIcon
    case 'earth':
      return EarthIcon
    case 'arrow-forward':
      return ArrowForward
    case 'arrow-back':
      return ArrowBack
    case 'tick':
      return TickIcon
    case 'info':
      return InfoIcon
    case 'active-tick':
      return ActiveTickIcon
    case 'calendar':
      return CalendarIcon
    case 'arrow-north-east':
      return ArrowNorthEastIcon
    case 'arrow-play':
      return ArrowPlayIcon
    case 'arrow-circle-down':
      return ArrowCircleDownIcon
    case 'success':
      return SuccessIcon
    case 'event':
      return EventIcon
    case 'location':
      return LocationIcon
    case 'schedule':
      return ScheduleIcon
    case 'close':
      return CloseIcon
    case 'heart':
      return HeartIcon
    case 'success-black':
      return SuccessBlackIcon
    case 'algbra-logo':
      return AlgbraLogo
    case 'arrow-outward':
      return ArrowOutward
  }
}

export default {
  SpinnerIcon,
  ArrowRight,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  PhoneIcon,
  EmailIcon,
  DownloadIcon,
  TickIcon,
  CalendarIcon,
  ArrowNorthEastIcon,
  ArrowPlayIcon,
  ArrowCircleDownIcon,
  SuccessIcon,
  EventIcon,
  LocationIcon,
  ScheduleIcon,
  CloseIcon,
  HeartIcon,
  SuccessBlackIcon,
  AlgbraLogo,
  ArrowOutward,
}
