import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './Heading.module.scss'

export type HeadingProps = {
  children?: ReactNode
  level?: number
  size?: number
  className?: string
  weight?: 100 | 300 | 400 | 500 | 600 | 700 | 900
  type?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'body'
  theme?: 'light' | 'dark'
} & React.HTMLAttributes<HTMLHeadingElement>

function Heading({
  children,
  level = 2,
  className,
  size,
  weight,
  type = 'lg',
  theme,
  style,
  ...restProps
}: HeadingProps): JSX.Element {
  const Tag = `h${level}`
  return React.createElement(
    Tag,
    {
      ...restProps,
      className: classNames(className, [
        styles[`type__${type}`],
        styles[`theme__${theme}`],
      ]),
      style: {
        fontSize: size ? `${size}rem` : undefined,
        fontWeight: weight && weight,
        ...style,
      },
    },
    children
  )
}

export default Heading
