import React from "react";
import classNames from 'classnames';
import styles from './Icon.module.scss';

export type IconProps = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  size?: number;
  className?: string;
} & React.SVGProps<SVGSVGElement>;

function Icon({ icon: Icon, ...rest }: IconProps): JSX.Element {
  const { size, className, fill, ...restIconProps } = rest;
  const iconProps = {
    ...restIconProps,
    fill: fill ? fill : 'currentColor',
  };
  if (size) {
    iconProps.width = size;
    iconProps.height = size;
  }
  return <Icon {...iconProps} className={classNames(styles.icon, className)} />;
}

export default Icon;
