import React from 'react'
import classNames from 'classnames'
import { ReactNode } from 'react'

export type GridContainerProps = {
  children: ReactNode
  fluid?: boolean
  className?: string
} & React.HTMLAttributes<HTMLElement>
function GridContainer({
  children,
  fluid = false,
  className,
  ...ariaProps
}: GridContainerProps): JSX.Element {
  return (
    <section
      className={classNames(`container`, { 'grid-lg': !fluid }, className)}
      {...ariaProps}
    >
      {children}
    </section>
  )
}

export default GridContainer
