import Permalink from '@algbra/ui/components/Permalink/Permalink'
import classNames from 'classnames'
import { filter, map } from 'rambdax'
import { HeaderMenuProps } from '../HeaderMenu/HeaderMenu'
import styles from './HeaderMenuMobile.module.scss'

export function HeaderMenuMobile(props: HeaderMenuProps) {
  const { links, subLinks, isCompact, isMobileMenuVisible } = props
  if (!isMobileMenuVisible) {
    return null
  }

  return (
    <nav
      className={classNames(styles.container, { [styles.compact]: isCompact })}
    >
      {map(link => {
        const filteredLinks = filter(
          sub => sub.parent === link.groupName && !sub.isBanner,
          subLinks
        )

        if (!link.groupName) {
          return (
            <div className={styles.item}>
              <Permalink className={styles.title} to={link.url}>
                {link.title}
              </Permalink>
            </div>
          )
        }

        return (
          <div className={styles.item}>
            <div className={styles.title}>{link.title}</div>
            <div className={styles.sublinks}>
              {map(
                sub => (
                  <div>
                    <Permalink to={sub.url} className={styles.sublink}>
                      {sub.title}
                    </Permalink>
                  </div>
                ),
                filteredLinks
              )}
            </div>
          </div>
        )
      }, links)}
    </nav>
  )
}
