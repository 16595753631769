export enum VAlign {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'flex-end',
  STRETCH = 'stretch',
}

export enum HAlign {
  START = 'flex-start',
  END = 'flex-end',
  CENTER = 'center',
  BETWEEN = 'space-between',
  AROUND = 'space-around',
  EVENLY = 'space-evenly',
}
