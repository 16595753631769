import Button from '@algbra/ui/components/Button/Button'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Icon from '@algbra/ui/components/Icon/Icon'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { HAlign, VAlign } from '@algbra/ui/enums/align'
import { ThemeContext } from '@algbra/ui/enums/theme'
import {
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET_BELOW,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby-link'
import { useContext, useEffect, useState } from 'react'
import useScrollTop from '../../utils/useScrollTop'
import { HeaderMenu } from '../HeaderMenu/HeaderMenu'
import { HeaderMenuMobile } from '../HeaderMenuMobile/HeaderMenuMobile'
import styles from './Header.module.scss'
import { Helmet } from 'react-helmet'

import { globalHistory } from '@reach/router'

export type HeaderProps = {
  hasBorder?: boolean
  hasBg?: boolean
  logoUrl?: string
  hasBlur?: boolean
  hasAnnouncement?: boolean
  simple?: boolean
}

export type PrimaryNavLink = {
  id: string
  title: string
  url: string
  target: string
  groupName: string
  isClickable: boolean
}
export type SecondaryNavLink = {
  id: string
  parent: string
  description?: string
  title: string
  url: string
  isBanner: boolean
  icon: GatsbyResolvedAsset
  target?: string
}

const QUERY_HEADER_NAVIGATION = graphql`
  query NavigationQuery {
    strapiGQL {
      navigationHeader {
        items {
          id
          title
          url
          target
          groupName
          isClickable
        }
        subLinks {
          id
          title
          description
          url
          parent
          isBanner
          target
          icon {
            id
            url
            ext
            resolved {
              publicURL
            }
          }
        }
      }
    }
  }
`

function Header(props: HeaderProps): JSX.Element {
  const {
    hasBorder = false,
    hasBg = true,
    logoUrl,
    hasBlur,
    simple,
    hasAnnouncement = false,
  } = props
  const { theme } = useContext(ThemeContext)
  const isTablet = useMediaQuery(MEDIA_QUERY_TABLET_BELOW)
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const [isMobileMenuVisible, setMobileMenuVisibility] =
    useState<boolean>(false)
  const isDarkScheme = theme === 'dark' || theme === 'darkTranslucent'
  let logoName = isDarkScheme ? 'logo-inverted' : 'logo'
  logoName = theme === 'pink' ? 'logo-on-pink' : logoName
  const buttonTheme = isDarkScheme ? 'light' : 'primary'
  const mobileMenuIconName = isMobileMenuVisible ? 'times' : 'burger'
  const logo = logoUrl || `/images/${logoName}.svg`
  const {
    strapiGQL: { navigationHeader },
  } = useStaticQuery(QUERY_HEADER_NAVIGATION)
  const isTop = useScrollTop()
  const isDev =
    typeof window !== 'undefined'
      ? /localhost|snapshot/.test(window?.origin)
      : false

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setMobileMenuVisibility(false)
    })
  }, [isMobileMenuVisible])

  useEffect(() => {
    if (document) {
      const body = document.querySelector('body')
      if (isMobileMenuVisible) {
        body.setAttribute('style', 'overflow: hidden')
      } else {
        body.setAttribute('style', '')
      }
    }
  }, [isMobileMenuVisible])

  return (
    <>
      <header
        className={classNames(styles.container, [styles[`theme__${theme}`]], {
          [styles.hasMobileMenu]: isMobileMenuVisible,
        })}
      >
        {isDev && (
          <Helmet>
            <script>{`
                window.markerConfig = {
                  project: '63862abf2693153962f962c7',
                  source: 'snippet'
                };
            `}</script>
            <script>{`
             !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};
             ["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){
               n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";
               var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
            
            `}</script>
          </Helmet>
        )}
        <div
          className={classNames(styles.innerHeader, {
            [styles.hasBorder]: hasBorder,
            [styles.hasBg]: hasBg,
            [styles.isCompact]: !isTop,
            [styles.hasBlur]: hasBlur,
          })}
        >
          <Container>
            <Row hAlign={HAlign.BETWEEN}>
              <Column auto={true} className={styles.logoCol}>
                <Permalink to="/" className={styles.logo}>
                  <img src={logo} width={118} height={40} alt="Algbra" />
                </Permalink>
              </Column>
              {!isTablet && !simple && (
                <Column
                  as="nav"
                  className={classNames(styles.nav, {
                    [styles.hasAnnouncement]: hasAnnouncement && isTop,
                  })}
                  aria-label="Main Navigation"
                >
                  <Row
                    vAlign={VAlign.CENTER}
                    gutter={false}
                    style={{
                      width: '100%',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Column>
                      <HeaderMenu
                        links={navigationHeader.items}
                        subLinks={navigationHeader.subLinks}
                        theme={theme}
                        isCompact={!isTop}
                      />
                    </Column>
                  </Row>
                </Column>
              )}
              {!simple && (
                <Column
                  auto={true}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    size="small"
                    theme={buttonTheme}
                    onClick={() => navigate('/download/')}
                    className={styles.button}
                  >
                    Download Now
                  </Button>
                  <div
                    className={styles.menuIcon}
                    onClick={() =>
                      setMobileMenuVisibility(!isMobileMenuVisible)
                    }
                  >
                    <Icon icon={getIconByName(mobileMenuIconName)} size={24} />
                  </div>
                </Column>
              )}
              {simple && (
                <Column auto={true}>
                  <Button
                    size="small"
                    theme="tetriary"
                    onClick={() => navigate('/')}
                    className={styles.button}
                  >
                    Back to Home
                  </Button>
                </Column>
              )}
            </Row>
          </Container>
        </div>
        {isTablet && (
          <HeaderMenuMobile
            links={navigationHeader.items}
            subLinks={navigationHeader.subLinks}
            theme={theme}
            isMobileMenuVisible={isMobileMenuVisible}
            isCompact={!isTop}
          />
        )}
      </header>
      {isMobileMenuVisible && (
        <div
          className={styles.sheet}
          onClick={() => setMobileMenuVisibility(false)}
        ></div>
      )}
    </>
  )
}

export default Header
