import { Column } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery,
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import classNames from 'classnames'
import { mapIndexed } from 'rambdax'
import { useEffect, useState } from 'react'
import { FooterPrimaryLink } from '../../types/footer'
import styles from './Footer.module.scss'

export type FooterLinkGroupProps = {
  category: string
  links: FooterPrimaryLink[]
}
export default function FooterLinkGroup(props: FooterLinkGroupProps) {
  const { category, links } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const [isVisible, setVisible] = useState<boolean>(!isMobile)
  const arrowIcon = isVisible ? 'arrow-up' : 'arrow-down'
  useEffect(() => {
    setVisible(!isMobile)
  }, [isMobile])

  const onMobileClick = () => {
    if (isMobile) {
      setVisible(!isVisible)
    }
  }
  return (
    <Column size={12} md={3}>
      <div
        className={classNames(styles.header, {
          [styles.clickable]: isMobile,
          [styles.active]: isVisible,
        })}
        onClick={onMobileClick}
      >
        <Heading type="body" level={3} className={styles.category}>
          {category}
        </Heading>
        {isMobile && <Icon icon={getIconByName(arrowIcon)} />}
      </div>
      {isVisible && (
        <ul>
          {mapIndexed(
            ({ url, title, target }, index) => (
              <li key={`footer-link-item-${category}-${index}`}>
                <Permalink to={url} isExternal={!!target} isBlank={!!target}>
                  {title}
                </Permalink>
              </li>
            ),
            links
          )}
        </ul>
      )}
    </Column>
  )
}
