import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { resolveAsAttachment } from '@algbra/ui/resolvers'
import classNames from 'classnames'
import { filter, find, map } from 'rambdax'
import { ReactNode, useState } from 'react'
import { PrimaryNavLink, SecondaryNavLink } from '../Header/Header'
import styles from './HeaderMenu.module.scss'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'

export type HeaderMenuProps = {
  links: PrimaryNavLink[]
  subLinks: SecondaryNavLink[]
  isMobileMenuVisible?: boolean
  theme: string
  isCompact?: boolean
  onMenuToggle?: (isActive: boolean) => void
}
export function HeaderMenuPrimaryLink({
  title,
  children,
  className,
  isCompact,
  onMenuToggle,
  url,
  isClickable,
}: PrimaryNavLink & {
  children?: ReactNode
  className?: string
  isCompact?: boolean
  url?: string
  onMenuToggle?: (isOpen: boolean) => void
}) {
  const [activeMenu, setActiveMenu] = useState<boolean>(false)
  const setMenuView = (isActive: boolean) => {
    setActiveMenu(isActive)
    onMenuToggle && onMenuToggle(isActive)
  }

  return (
    <div
      className={classNames(
        styles.primaryNavLink,
        {
          [styles.active]: activeMenu,
          [styles.compact]: isCompact,
          [styles.pointer]: !children && url,
        },
        className
      )}
      onMouseEnter={() => setMenuView(true)}
      onMouseLeave={() => setMenuView(false)}
    >
      {isClickable ? (
        <Permalink
          className={styles.title}
          to={url}
          isExternal={false}
          isBlank={false}
        >
          {title}
        </Permalink>
      ) : (
        <span className={styles.title}>{title}</span>
      )}
      {children && (
        <div className={styles.children}>
          <div className={styles.childrenCentered}>
            <div className={styles.childrenBg}>{children}</div>
          </div>
        </div>
      )}
    </div>
  )
}
export function HeaderMenu({
  links,
  subLinks,
  onMenuToggle,
  isCompact,
  theme,
}: HeaderMenuProps) {
  return (
    <nav>
      <div
        className={classNames(styles.primaryNav, styles[`theme__${theme}`], {
          [styles.compact]: isCompact,
        })}
      >
        {map(link => {
          const filteredLinks = filter(
            sub => sub.parent === link.groupName && !sub.isBanner,
            subLinks
          ).sort((a, b) => a.title.localeCompare(b.title))
          const banner = find(
            sub => sub.isBanner && link.groupName === sub.parent,
            subLinks
          )

          return (
            <HeaderMenuPrimaryLink
              key={link.id}
              {...link}
              isCompact={isCompact}
              onMenuToggle={onMenuToggle}
              className={classNames({
                [styles.twoCol]: filteredLinks.length > 3,
              })}
            >
              {filteredLinks.length > 0 && (
                <>
                  <div className={styles.secondaryLinks}>
                    {map(
                      ({ title, id, url, target }: SecondaryNavLink) => (
                        <Permalink
                          className={styles.secondaryLink}
                          activeClassName={styles.active}
                          to={url}
                          key={id}
                          isExternal={!!target}
                          isBlank={!!target}
                        >
                          {title}
                        </Permalink>
                      ),
                      filteredLinks
                    )}
                  </div>
                  {banner && (
                    <Permalink to={banner.url} className={styles.banner}>
                      {banner.icon && (
                        <div className={styles.image}>
                          <img
                            src={resolveAsAttachment(banner.icon)}
                            alt={banner.title}
                          />
                        </div>
                      )}
                      <div className={styles.content}>
                        <FreeText type="m" className={styles.bannerTitle}>
                          {banner.title}
                        </FreeText>
                        {banner.description && (
                          <FreeText
                            type="sm"
                            className={styles.bannerDescription}
                          >
                            {banner.description}
                          </FreeText>
                        )}
                        <Icon
                          icon={getIconByName('arrow-right')}
                          fill="#757575"
                          size={20}
                        />
                      </div>
                    </Permalink>
                  )}
                </>
              )}
            </HeaderMenuPrimaryLink>
          )
        }, links)}
      </div>
    </nav>
  )
}
