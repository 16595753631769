import classNames from 'classnames'
import React, { ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './Whitespace.module.scss'

export type WhitespaceProps = {
  children: ReactNode
  as?: keyof HTMLElementTagNameMap
  type?: 'xl' | 'lg' | 'md' | 'sm' | 'none'
  isOuter?: boolean
  className?: string
  hasAnimation?: boolean
} & React.HTMLAttributes<HTMLElement>
export default function Whitespace(props: WhitespaceProps) {
  const {
    as = 'section',
    className,
    isOuter = false,
    children,
    type = 'lg',
    hasAnimation = true,
    ...restProps
  } = props

  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true })
  const [isInView, setInView] = useState(!hasAnimation)

  useEffect(() => {
    if (inView) {
      setInView(true)
    }
  }, [inView])

  const baseClassName = isOuter ? `margin__${type}` : `padding__${type}`
  return React.createElement(
    as,
    {
      ...restProps,
      ref,
      className: classNames(
        [styles[baseClassName]],
        {
          [styles.hasAnimation]: hasAnimation,
          [styles.inViewEffect]: isInView && hasAnimation,
        },
        className
      ),
    },
    children
  )
}
